'use strict';
console.log("instructions service js")
angular.module('kljDigitalLibraryApp')
    .config(["$stateProvider",
        function($stateProvider) {
            $stateProvider
                .state('index.instructions', {
                    url: '/instructions',
                    templateUrl: 'app/instructions/instructions.html'
                    // controller: 'InstructionsCtrl'
                });
        }
    ]);